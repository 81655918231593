.flex-row{
    display: flex;
    flex-direction: row;
}

.flex-row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-col{
    display: flex;
    flex-direction: column;
}

.flex-col-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.max-width{
    max-width: 1040px;
}


@media (max-width:1200px ){
    .max-width{
        max-width: 1000px;
    }
}



