.job-card{
    border: 1px solid #E0E0E0;
    border-radius: 24px;
    gap: 12px;
    align-items: flex-start;
    height: fit-content;
}

.job-card:hover{
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
}

.career-container{
    min-height: calc(100vh - 200px);
}

@media (max-width:600px) {
    .careers-header p:nth-of-type(1){
        
        text-align: center;
    }

    .careers-header p:nth-of-type(2){
        
        text-align: center;
    }
    .job-card{
        padding: 15px !important;
    }

    
}