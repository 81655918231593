


@media (max-width:900px){
    .inquiry-container{
        justify-content: center !important;
        flex-wrap: wrap;
     
    }
}


/* inquiry form */
.inquiry-form{
    border: 3px solid #00B295;
    border-radius: 16px;
    padding: 32px 24px !important;
    background-color: #ffff;
}

@media (max-width:1080px){
    .inquiry-container{
        gap:20px
    }
}



/* modal */

.modal-item:nth-of-type(1){
    background-color: #00B295;
    height: 50%;
    width: 100%;
    border-radius: 24px 24px 0px 0px;
    position: relative;

}

.hand-sign{
    position: absolute;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
    width: 280px;
    height: 264px;
}

.modal-item:nth-of-type(2){
    background-color: #ffff;
    height: 50%;
    width:100%;
    border-radius: 0px 0px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-item:nth-of-type(2) h1{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 133.4%;
    margin-bottom: 0px;
    
}

.modal-item:nth-of-type(2) p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
}



/*
.MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: pink !important;
  }
*/
.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #00B295 !important;
  }



.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #00B295 !important;
}

.Mui-focused .MuiFormLabel-root{
    color: #00B295 !important;
}



/* For standard variant */
.MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom-color: #00B295 !important; /* Replace 'desiredBorderColor' with the color you want. */
  }
  
  /* For outlined variant */
  .MuiOutlinedInput-root:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline {
    border-color: #00B295 !important;/* Replace 'desiredBorderColor' with the color you want. */
  }
  


.MuiPopover-paper{
    height: 300px !important;
} 
