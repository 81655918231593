.employee-card {
    height: 296px;
    width: 224px;
    background-color: white;
    border-radius: 24px;
    gap:16px
    
}

.employee-card:hover{
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12));
}

.animated-doctor{
    width: 475px;
height: 332px;
}

.staff-picture{
    width: 560px;
    height: 240px;
}

.blue{
    color:#10A5E7
}

@media (max-width:1200px){

    .hero-item{
        justify-content: center !important;
    }
    .animated-doctor{
        margin-top: 84px;
    }
    .staff-picture{
        height: 200px;
        width: 431px;
    }
    
}

@media (max-width:900px){
    .animated-doctor{
        margin-top: 0px;
    }
    .hero-item{
        text-align: center;
        justify-content: center !important;
    }
    .about-inquiry-container{
        gap: 20px !important;
        text-align: center;
    }
    .staff-picture{
        width: 504px;
        height: 219px;
    }
}

@media(max-width:600px){
    .animated-doctor{
        width:405px;
        height:282px
    }
    .hero-item{
        gap: 40px !important;
        margin-bottom: 40px !important;
    }
    .staff-picture{
        height: 200px;
        width: 431px;
    }
}

@media(max-width:500px){
    .animated-doctor{
        width:303px;
        height: 211px;
    }

    .staff-picture{
        width:328px;
        height: 152px;
    }
    
}

