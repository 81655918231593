
.footer-container{
    position: relative;
    background: #00254B;
    display: flex;
    flex-direction: column;
}



.footer-top{
    border-bottom: 1px solid #10A5E7;;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 32px 20px;
}

.top-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:102px;
    align-self: center;
    width:100%;
    max-width: 1040px;
}
.footer-top h2{
    margin-top: 0px;
    color: #10A5E7;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 266%;
}



.footer-top p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    color: #FFFFFF;
}

.socials-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.socials-container svg{
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    
}

.footer-logo{
    width: 32.25px;
    height: 32.01px;
}

.footer-bottom{
    height: 52px;
}
.footer-bottom p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    margin-right: 20%;
    text-align: right;
    letter-spacing: 0.17px;
    
    color: #FFFFFF;
    
}

.footer-link:hover{
    color:#10A5E7;
    cursor: pointer;
}


@media (max-width:900px){
    .footer-logo{
        display: none;
    }
}

@media (max-width:700px){
    .top-container{
        gap: 10px;

    }

    .top-container p {
        font-size: 12px;
    }

    .top-container h2{
        font-size: 11px;
    }
}


