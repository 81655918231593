.hero-image{
    width: 556px;
    height: 268px;

    max-width: 556px;
    max-height: 268px;
}

.brand-img{
    opacity: 0.8;
}

.about-item-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #10A5E7 !important;
    color: #FFFFFF;
    border-radius: 48px;
}

.stat-container{
    position:relative;
    box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    top:-65px; 
    max-width: 880px;
    height: 120px;
}

@media (max-width:450px){
    .review-item{
        flex-direction: column;
        align-items: center;
    }
    .review-container{
        padding-top: 0px !important;
    }

    .stat-container{
        top: -50px !important;
    }

    
}

@media (max-width:600px){
    .home-hero-container div{
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .home-hero-title{
        font-size: 25px !important;
    }

    .home-about-title{
        font-size: 19px;
    }
    .money-stat p{
        font-size: 20px;
    }

        
    .money-stat img{
        height: 38px;
        width: 38px;
    }

    .home-stat-text{
        padding: 15px 10px !important;
        gap: 10px;
        text-align: center;
    }
}

@media (max-width:780px){

    .review-container{
        padding-top: 0px;
       
    }

    .stat-container{
        flex-wrap: wrap ;
        height: fit-content;
        
    }

    .stat-container > :first-child{
        width: 100%;
    }

    .stat-container > :nth-child(2){
        width: 100%;
        justify-content: space-around;
        padding: 23px 10px !important;
        
    }

    .home-stat-text button{
        padding: 4px 15px;
        font-size: 12px;
    }
   
}

@media (max-width:940px){
    .hero-image{
        width: 100% !important;
        height:100% !important;
    }

    


    .cta-container{
        gap:10px !important;
        text-align: center;
    }

    

}

@media (max-width:1100px){
    .money-stat{
        padding: 26px 28px;
        
    }
    .money-stat p {
        font-size: 28px;
    }

    .home-stat-text{
        flex-wrap: wrap;
        padding: 20px !important;
        gap: 15px !important;
        text-align: center !important;
        justify-content: center !important;
    }
    
}

@media ( max-width:1200px){

    .hero-image{
        width: 445px;
        height:214px
    }

    .home-hero-container{
        justify-content: center !important;
        
    }

   

}

